.footer {
  .background-footer {
    background-image: url('images/footer.png');
    background-position: 0% 80%;
    background-repeat: no-repeat; }

  .pattern {
    padding-bottom: 220px;
    padding-top: 40px; }

  .background {
    border: 0 none; } }

.standard-body {
  .footer {
    .background {
      border: 0 none; } } }

.scrollup {
  background-color: rgba(40, 160, 101, 0.5);
  border-radius: 18px;
  bottom: 150px;
  display: none;
  height: 60px;
  outline: none;
  padding: 6px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 60px;
  z-index: 99999;

  &:hover {
    background-color: rgba(40, 160, 101, 0.7);
    border: none;
    box-shadow: none;
    outline: none; }

  i {
    color: #fff;
    font-size: 42px; } }

.shoppingCartBtn {
  background-color: rgba(40, 160, 101, 0.5);
  border-radius: 18px;
  bottom: 80px;
  display: none;
  height: 60px;
  outline: none;
  padding: 15px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 60px;
  z-index: 99999;

  &:hover {
    background-color: rgba(40, 160, 101, 0.7);
    border: none;
    box-shadow: none;
    outline: none; }

  i {
    color: #fff;
    font-size: 30px; } }

// * copyright

.copyright {
  background-color: #325214;

  .pattern {
    color: #fff;
    font-size: 16px;
    padding-bottom: 20px;

    a {
      color: #fff;
      font-size: 16px; }

    .line {
      margin-bottom: 20px; } } }

// no drunk driving
.no-drunk-driving {
  background: #000;
  bottom: 0;
  color: #fff;
  font-family: 'Roboto', Arial, Microsoft JhengHei, Heiti TC !important;
  font-size: 40px;
  position: fixed;
  vertical-align: top;
  width: 100%;
  z-index: 100;

  img {
    margin-top: -6px;
    vertical-align: middle; } }

.scrollup {
  z-index: 9999; }

body {
  padding-bottom: 68px;
  font-size: 16px; }

ul {
  &.megamenu {
    > li {
      > a {
        font-size: 16px; } } } }

.breadcrumb {
  .container {
    h1 {
      font-size: 40px; } } }

.header {
  .header-top-inner {
    font-size: 16px; } }

.footer {
  h4 {
    font-size: 20px; } }

.js-shoppingCartCounts {
  position: absolute;
  right: -5px;
  bottom: 0;
  background-color: #f00;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  text-align: center;
  height: 20px;
  line-height: 20px;

  &:empty {
    display: none; } }

@media (min-width: 1400px) {
  .no-drunk-driving {
    text-align: center; } }

@media (max-width: 991px) {
  .copyright ul {
    float: none;
    padding-top: 5px; } }

@media (min-width: 992px) {
  .copyright ul {
    padding-top: 15px; }

  .container-megamenu {
    &.scroll-to-fixed-fixed {
      background-color: #fff;
      box-shadow: 0px -10px 30px rgba(black, .3);
      width: 100% !important;
      left: 0 !important; } } }

@media (max-width: 859px) {
  .no-drunk-driving {
    font-size: 20px; }

  body {
    padding-bottom: 44px; } }

@media (max-width: 463px) {
  body {
    padding-bottom: 78px; } }

.cart-total {
  overflow: hidden; }

.whishlist-btn {
  font-size: 12px;
  padding: 0 10px; }

.product-info .price .price-new,
.product-grid .product .price .price-new,
.special-price .price,
.product-compare table .price-new,
.price .price-new {
  color: #007d39; }

// 手機版版面調整
@media (max-width: 991px) {
  .top-seach,
  .logo-home,
  .typo-top-cart {
    height: 75px; }

  .button-search {
    width: 40px; } }

@media (max-width: 767px) {
  // xs
  .breadcrumb {
    margin-top: 0; }

  iframe {
    width: 100% !important; }

  .megaMenuToggle {
    cursor: default; }

  .js-megamenuToogle {
    cursor: pointer; }

  .settings-topbar {
    position: fixed;
    top: 5px;
    z-index: 100001; }

  .typo-cart {
    position: fixed;
    top: 10px;
    right: 70px;
    z-index: 100001; }

  .camera_slider {
    margin-top: 0; }

  #main {
    padding-top: 54px;

    .header-inner {
      > .row {
        > div {
          &:first-child,
          &:last-child {
            width: 0;
            padding-left: 0;
            padding-right: 0; }

          &:nth-child(2) {
            width: 100%; } } } }

    .container-megamenu {
      position: fixed;
      right: 0;
      top: 0;
      left: 0;
      z-index: 100000;
      background-color: #fff;
      box-shadow: 0px -10px 30px rgba(black, .3); }

    .button-search {
      position: fixed;
      top: 13px;
      right: 110px;
      z-index: 100001; } }

  .responsive .megamenu-wrapper {
    margin-top: 0; }

  .megamenuToogle-wrapper {
    .container {
      height: 54px;

      > div {
        padding-top: 12px;
        float: right; } } }

  .megamenu {
    height: calc(100vh - 88px);
    overflow-y: auto; }

  .responsive #main .breadcrumb .container {
    padding-top: 30px;
    padding-bottom: 30px; }

  .settings .settings-inner {
    right: inherit;
    padding-top: 0; }

  .product-list > div img {
    margin: auto; }

  .product-shop {
    padding-top: 10px; }

  .product-list .product-shop .desc {
    margin-bottom: 20px;
    margin-top: 10px; }

  .scrollup {
    bottom: 140px;
    height: 48px;
    width: 48px;
    padding: 3px;
    right: 21px;
    border-radius: 15px;

    i {
      font-size: 35px; } }

  .shoppingCartBtn {
    bottom: 85px;
    height: 48px;
    width: 48px;
    padding: 12px;
    right: 21px;
    border-radius: 15px;

    i {
      font-size: 25px; } }

  .footer .background-footer {
    background-size: contain;
    background-position: center bottom; }

  .mobile-landscape {
    .product-list {
      &.active {
        > .row {
          clear: both;

          > .col-xs-12 {
            float: left;
            width: 65%;

            &:first-child {
              width: 35%; } } } } } } }

.center-column {
  padding-top: 30px; }

.category-info > p {
  padding-bottom: 5px; }

// end of 手機版版面調整

.typo-ajax-container .typo-ajax-checkout .view-cart {
  float: right; }

//調整按鈕顏色

.button:hover,
.btn:hover {
  border-color: #007d39;
  background-color: #007d39; }

.box > {
  .prev:hover,
  .next:hover {
    border-color: #007d39;
    background: #007d39; } }

.add-to-links {
  > div a:hover {
    border-color: #007d39;
    background: #007d39; } }

a:hover,
.footer .pattern a:hover,
.header-top-inner a:hover {
  color: #007d39; }

#contactForm {
  button {
    border: solid 1px #999;

    span {
      color: #999;
      background-color: #fff; }

    &:hover span {
      color: #fff;
      border-color: #007d39;
      background-color: #007d39; } } }

// color of menu
ul.megamenu {
  > li {
    > a:hover,
    &.active > a,
    &.home > a,
    &:hover > a {
      color: #007d39; } }

  li .sub-menu .content .hover-menu .menu ul li a:hover {
    color: #007d39; } }

.responsive ul.megamenu li .sub-menu .content {
  .hover-menu .menu ul li a:hover, .static-menu .menu ul li a:hover, .hover-menu .menu ul li.active > a, .static-menu .menu ul li.active > a {
    color: #007d39; } }

.MINI #twcad2 {
  left: 80px !important;
  top: -25px !important; }
